import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CalcOnlyHotelPackageRequest,
  CalcOnlyHotelPackageResponse,
  SearchOnlyHotelPackagesRequest,
  SearchOnlyHotelPackagesResponse
} from '@appTypes/api.types';
import { environment } from '@env/environment';

@Injectable()
export class HotelsApiService {
  private readonly apiEndPoint: string = environment.urls.api + '/OnlyHotelPackages/';
  private readonly mockupsApiEndPoint: string = 'assets/_api_mockups_/Hotels/';

  constructor(private httpClient: HttpClient) {}

  public searchHotels(payload: SearchOnlyHotelPackagesRequest) {
    return this.httpClient.post<SearchOnlyHotelPackagesResponse>(this.apiEndPoint + 'SearchHotelPrices', payload);
  }

  public calcPackage(payload?: CalcOnlyHotelPackageRequest) {
    return this.httpClient.post<CalcOnlyHotelPackageResponse>(this.apiEndPoint + 'CalcPackage', payload);
  }
}
