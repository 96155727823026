<div class="page-bg"></div>
@if (searchResults$ | async; as searchResults) {
  <div class="page-wrapper">
    <app-hotels-search-desktop-form />
    @if (searchResults.loadState.status) {
      <app-hotels-results-desktop [searchResults]="searchResults" />
    }
  </div>
}
<app-kz-currencies-info />
