import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OnlyHotelPackage, SearchOnlyHotelPackagesResponse } from '@appTypes/api.types';
import { IOnlyHotelPackage } from '@appTypes/redefined-types';
import { collapseAnimation } from '@core/misc/animations.misc';
import { RequestStateModel } from '@core/store/store.models';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { HotelsColumnsSort, HotelsSortableColumnsType } from '@pages/hotels-page/hotels.types';
import { HotelsSearchFormService } from '@pages/hotels-page/services/hotels-search-form.service';
import { hotelsOpenDetails, hotelsSearchHotels } from '@pages/hotels-page/state/hotels.actions';
import { SearchTourPackagesPaging } from '@pages/tours-page/tours.types';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'app-hotels-results-desktop',
  templateUrl: './hotels-results-desktop.component.html',
  styleUrl: './hotels-results-desktop.component.scss',
  animations: [collapseAnimation()]
})
export class HotelsResultsDesktopComponent {
  @Input() public searchResults!: RequestStateModel<SearchOnlyHotelPackagesResponse>;
  public currencySymbols = this.storeService.selectSnapshot(AppState.currencySymbols);
  public columnSortOrders?: HotelsColumnsSort;
  public collapsed: number | null = null;
  public readonly itemsTypedTemplateToken!: { item: OnlyHotelPackage; isChild?: boolean; index: number };
  constructor(
    private storeService: StoreService,
    public service: HotelsSearchFormService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  public onCollapse(index: number) {
    if (this.collapsed === index) this.collapsed = null;
    else this.collapsed = index;
  }
  public onPageSelect(paging: SearchTourPackagesPaging) {
    this.storeService.dispatch(new hotelsSearchHotels({ ...paging, ...this.columnSortOrders }));
    this.collapsed = null;
  }
  public onOpenDetails(data: IOnlyHotelPackage) {
    this.storeService.dispatch(new hotelsOpenDetails(data));
    this.collapsed = null;
  }
  public onPerPageChange(paging: SearchTourPackagesPaging) {
    this.service.pageRowCount.set(paging.pageRowCount ?? 25);
    this.storeService.dispatch(new hotelsSearchHotels({ ...paging, ...this.columnSortOrders }));
    this.collapsed = null;
  }

  public sortColumn(sortByField: HotelsSortableColumnsType) {
    this.collapsed = null;
    if (this.searchResults.loadState.status === 'loading') {
      this.snackBar.open(
        this.translateService.instant('Data is already in the loading process. Please wait until it is fully loaded.'),
        '',
        {
          panelClass: 'snackbar-warning',
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        }
      );
      return;
    }
    this.columnSortOrders = {
      sortByField,
      sortOrder:
        this.columnSortOrders?.sortByField === sortByField && this.columnSortOrders?.sortOrder === 'ASC'
          ? 'DESC'
          : 'ASC'
    };
    this.storeService.dispatch(
      new hotelsSearchHotels({
        pageNumber: this.searchResults.response?.pageNumber,
        pageRowCount: this.service.pageRowCount(),
        pagingId: this.searchResults.response?.pagingId,
        ...this.columnSortOrders
      })
    );
    this.collapsed = null;
  }
}
