import { Component } from '@angular/core';
import { HotelsState } from '@pages/hotels-page/state/hotels.state';
import { StoreService } from '../../../../core/store/store.service';

@Component({
  selector: 'app-hotels-desktop-page',
  templateUrl: './hotels-desktop-page.component.html',
  styleUrl: './hotels-desktop-page.component.scss'
})
export class HotelsDesktopPageComponent {
  public readonly searchResults$ = this.storeService.select(HotelsState.searchResults);
  constructor(private storeService: StoreService) {}
}
