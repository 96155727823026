import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { MobileHotelFilterMobileFormComponent } from '@pages/_common/mobile-hotel-filter-mobile-form/mobile-hotel-filter-mobile-form.component';
import { MobileNumberOfPassengersFormComponent } from '@pages/_common/mobile-number-of-passengers-form/mobile-number-of-passengers-form.component';
import { MobilePriceAndServicesFormComponent } from '@pages/_common/mobile-price-and-services-form/mobile-price-and-services-form.component';
import { HotelsBookingInstructionsComponent } from '@pages/hotels-page/desktop/hotels-search-desktop-form/hotels-booking-instructions/hotels-booking-instructions.component';
import { KzDesktopHotelCardComponent } from '@shared/components/desktop/_elements/kz-desktop-hotel-card/kz-desktop-hotel-card.component';
import { KzMobileHotelCardComponent } from '@shared/components/desktop/_elements/kz-mobile-hotel-card/kz-mobile-hotel-card.component';
import { KzDesktopButtonPopupComponent } from '@shared/components/desktop/kz-desktop-button-popup/kz-desktop-button-popup.component';
import { KzDesktopDatePickerComponent } from '@shared/components/desktop/kz-desktop-date-picker/kz-desktop-date-picker.component';
import { KzDesktopDateRangePickerComponent } from '@shared/components/desktop/kz-desktop-date-range-picker/kz-desktop-date-range-picker.component';
import { KzDesktopLoadingWindowComponent } from '@shared/components/desktop/kz-desktop-loading-window/kz-desktop-loading-window.component';
import { KzDesktopLoadingWindowService } from '@shared/components/desktop/kz-desktop-loading-window/kz-desktop-loading-window.service';
import { KzDesktopMultiSelectBoxComponent } from '@shared/components/desktop/kz-desktop-multi-select-box/kz-desktop-multi-select-box.component';
import { KzDesktopMultiSelectComponent } from '@shared/components/desktop/kz-desktop-multi-select/kz-desktop-multi-select.component';
import { KzDesktopNumberPickerComponent } from '@shared/components/desktop/kz-desktop-number-picker/kz-desktop-number-picker.component';
import { KzDesktopPaginationComponent } from '@shared/components/desktop/kz-desktop-pagination/kz-desktop-pagination.component';
import { KzDesktopSelectComponent } from '@shared/components/desktop/kz-desktop-select/kz-desktop-select.component';
import { KzCurrenciesInfoComponent } from '@shared/components/kz-currencies-info/kz-currencies-info.component';
import { KzMessageBoxComponent } from '@shared/components/kz-message-box/kz-message-box.component';
import { KzStarsComponent } from '@shared/components/kz-stars/kz-stars.component';
import { KzMobileDatePickerComponent } from '@shared/components/mobile/kz-mobile-date-picker/kz-mobile-date-picker.component';
import { KzMobileInputFormComponent } from '@shared/components/mobile/kz-mobile-input-form/kz-mobile-input-form.component';
import { KzMobileMultiSelectComponent } from '@shared/components/mobile/kz-mobile-multi-select/kz-mobile-multi-select.component';
import { KzMobileSearchButtonComponent } from '@shared/components/mobile/kz-mobile-search-button/kz-mobile-search-button.component';
import { KzMobileSelectComponent } from '@shared/components/mobile/kz-mobile-select/kz-mobile-select.component';
import { KzButtonLoadingDirective } from '@shared/directives/kz-button-loading.directive';
import { KzInputDirective } from '@shared/directives/kz-input.directive';
import { TypedTemplateDirective } from '@shared/directives/typed-template.directive';
import { DynamicOverlayModule } from '@shared/modules/dynamic-overlay/dynamic-overlay.module';
import { KzPricePipe } from '@shared/pipes/kz-price.pipe';
import { ParseIntPipe } from '@shared/pipes/parse-int.pipe';
import { RunFunctionPipe } from '@shared/pipes/run-function.pipe';
import { SnakeCasePipe } from '@shared/pipes/snake-case.pipe';
import { HotelDetailsDesktopViewComponent } from './desktop/hotel-details-desktop-view/hotel-details-desktop-view.component';
import { HotelsDesktopPageComponent } from './desktop/hotels-desktop-page/hotels-desktop-page.component';
import { HotelsResultsDesktopComponent } from './desktop/hotels-results-desktop/hotels-results-desktop.component';
import { HotelsSearchDesktopFormComponent } from './desktop/hotels-search-desktop-form/hotels-search-desktop-form.component';
import { HotelsPageRoutingModule } from './hotels-page-routing.module';
import { HotelDetailsMobileComponent } from './mobile/hotel-details-mobile/hotel-details-mobile.component';
import { HotelsMobilePageComponent } from './mobile/hotels-mobile-page/hotels-mobile-page.component';
import { HotelsResultMobileComponent } from './mobile/hotels-result-mobile/hotels-result-mobile.component';
import { HotelsSearchMobileFormComponent } from './mobile/hotels-search-mobile-form/hotels-search-mobile-form.component';
import { HotelsApiService } from './services/hotels-api.service';
import { HotelsCommonService } from './services/hotels-common.service';
import { HotelsSearchFormService } from './services/hotels-search-form.service';
import { HotelsState } from './state/hotels.state';

@NgModule({
  declarations: [
    HotelDetailsDesktopViewComponent,
    HotelDetailsMobileComponent,
    HotelsDesktopPageComponent,
    HotelsMobilePageComponent,
    HotelsResultMobileComponent,
    HotelsResultsDesktopComponent,
    HotelsSearchDesktopFormComponent,
    HotelsSearchMobileFormComponent,
    HotelsBookingInstructionsComponent
  ],
  imports: [
    CommonModule,
    HotelsPageRoutingModule,
    KzDesktopButtonPopupComponent,
    KzDesktopDateRangePickerComponent,
    KzDesktopDatePickerComponent,
    KzDesktopMultiSelectBoxComponent,
    KzDesktopMultiSelectComponent,
    KzDesktopNumberPickerComponent,
    KzDesktopSelectComponent,
    KzInputDirective,
    MatProgressSpinnerModule,
    KzButtonLoadingDirective,
    TranslateModule,
    KzDesktopLoadingWindowComponent,
    KzDesktopPaginationComponent,
    KzStarsComponent,
    KzPricePipe,
    ParseIntPipe,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    NgxsModule.forFeature([HotelsState]),
    NgxsFormPluginModule,
    RunFunctionPipe,
    MatProgressBarModule,
    TypedTemplateDirective,
    SnakeCasePipe,
    KzMessageBoxComponent,
    /*  */
    KzMobileSearchButtonComponent,
    KzMobileSelectComponent,
    KzMobileDatePickerComponent,
    KzMobileInputFormComponent,
    MobileNumberOfPassengersFormComponent,
    KzMobileMultiSelectComponent,
    KzCurrenciesInfoComponent,
    MobileHotelFilterMobileFormComponent,
    MobilePriceAndServicesFormComponent,
    KzDesktopHotelCardComponent,
    KzMobileHotelCardComponent,
    DynamicOverlayModule
  ],
  providers: [HotelsApiService, HotelsCommonService, HotelsSearchFormService, KzDesktopLoadingWindowService]
})
export class HotelsPageModule {}
