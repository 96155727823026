import { Component, OnInit } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { HotelsSearchFormService } from '@pages/hotels-page/services/hotels-search-form.service';
import { appSetCurrency } from 'src/app/state/app.actions';
import { AppPersistedState } from 'src/app/state/app.persisted.state';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'app-hotels-search-desktop-form',
  templateUrl: './hotels-search-desktop-form.component.html',
  styleUrl: './hotels-search-desktop-form.component.scss'
})
export class HotelsSearchDesktopFormComponent implements OnInit {
  public currencies$ = this.storeService.select(AppState.currencies);
  public activedCurrency$ = this.storeService.select(AppPersistedState.activedCurrency);

  constructor(
    public service: HotelsSearchFormService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.service.init();
  }
  public onCurrencySelect(currency: string) {
    this.storeService.dispatch(new appSetCurrency(currency));
  }
}
