import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IS_MOBILE } from '@core/misc/misc.utils';
import { HotelsDesktopPageComponent } from './desktop/hotels-desktop-page/hotels-desktop-page.component';
import { HotelsMobilePageComponent } from './mobile/hotels-mobile-page/hotels-mobile-page.component';

const routes: Routes = [
  {
    path: '',
    component: IS_MOBILE ? HotelsMobilePageComponent : HotelsDesktopPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelsPageRoutingModule {}
