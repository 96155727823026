import { CatalogueGetArrivalDistrictsListParams, CatalogueGetHotelsListParams } from '@appTypes/api.types';
import { SearchOnlyHotelPackagesPaging } from '../hotels.types';
import { IOnlyHotelPackage } from '@appTypes/redefined-types';

export class hotelsReset {
  static readonly type = '[Hotels] Reset';
}

export class hotelsSearchHotels {
  static readonly type = '[Hotels] SearchHotels';
  constructor(public paging?: SearchOnlyHotelPackagesPaging) {}
}

export class hotelsGetLocations {
  static readonly type = '[Hotels] GetLocations';
  constructor(
    public params: CatalogueGetArrivalDistrictsListParams,
    public refresh = false
  ) {}
}

export class hotelsGetDestinationCountries {
  static readonly type = '[Hotels] GetDestinationCountries';
  constructor(public refresh = false) {}
}
export class hotelsGetHotels {
  static readonly type = '[Hotels] GetHotels';
  constructor(
    public params: CatalogueGetHotelsListParams,
    public refresh = false
  ) {}
}

export class hotelsOpenDetails {
  static readonly type = '[Hotels] OpenDetails';
  constructor(public data: IOnlyHotelPackage) {}
}

export class hotelsResetResult {
  static readonly type = '[Hotels] hotelsResetResult';
}
