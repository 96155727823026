import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { IHotelFilterFormData } from '@pages/_common/mobile-hotel-filter-mobile-form/mobile-hotel-filter-mobile-form.component';
import { INumberOfPassengersFormData } from '@pages/_common/mobile-number-of-passengers-form/mobile-number-of-passengers-form.component';
import { IPricesAndServicesFormData } from '@pages/_common/mobile-price-and-services-form/mobile-price-and-services-form.component';
import { HotelsSearchFormService } from '@pages/hotels-page/services/hotels-search-form.service';
import { HotelsState } from '@pages/hotels-page/state/hotels.state';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-hotels-search-mobile-form',
  templateUrl: './hotels-search-mobile-form.component.html',
  styleUrl: './hotels-search-mobile-form.component.scss'
})
export class HotelsSearchMobileFormComponent implements OnInit {
  @ViewChild('searchFormRef') searchFormRef!: TemplateRef<unknown>;

  public modalRef?: DialogRef;

  public findCountryNameFn = (code: string) =>
    this.storeService.selectSnapshot(HotelsState.findDestinationCountryName(code));

  public get passengersInfo() {
    const adults = this.service.formGroup.controls.adults.value ?? 0;
    const infants =
      this.service.formGroup.controls.childrenAges.controls.filter((e) => e.value && e.value <= 2)?.length ?? 0;
    const children = (this.service.formGroup.controls.children.value ?? 0) - (infants ?? 0);
    let result = '';
    if (adults > 0) result = this.translateService.instant('adult') + ' ' + adults;
    if (children > 0) result = result + ', ' + this.translateService.instant('child') + ' ' + children;
    if (infants > 0) result = result + ', ' + this.translateService.instant('infant') + ' ' + infants;
    return result;
  }
  constructor(
    public service: HotelsSearchFormService,
    private dynamicModalService: DynamicModalService,
    private storeService: StoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.service.init();
  }
  public openSearchForm() {
    const storeParams = this.service.formGroup.getRawValue();
    this.modalRef = this.dynamicModalService.open({
      title: 'Change search parameters',
      template: this.searchFormRef,
      height: '85vh'
    }) as DialogRef;
    this.modalRef.closed.pipe(take(1)).subscribe((action) => {
      if (!action) {
        this.modalRef = undefined;
        this.storeService.dispatch(new UpdateFormValue({ path: this.service.ngxsForm, value: storeParams }));
        this.service.formGroup.setValue(storeParams);
      }
    });
  }

  public onHotelFilterCallback(data: IHotelFilterFormData) {
    this.service.formGroup.controls.hotelClasses.setValue(data.hotelClasses ?? null);
    this.service.formGroup.controls.mealPlans.setValue(data.mealPlans ?? null);
    this.service.formGroup.controls.hotelTypes.setValue(data.hotelTypes ?? null);
    this.service.updateHotelFilters();
  }
  public onNumberOfTouristsCallback(data: INumberOfPassengersFormData) {
    this.service.formGroup.controls.adults.setValue(data.adults);
    this.service.formGroup.controls.children.setValue(data.children);
    this.service.onAddChildren(data.children);
    if (data.childrenAges.length) {
      for (let index = 0; index < data.childrenAges.length; index++)
        this.service.childrenAges.at(index)?.setValue(data.childrenAges[index]);
    }
  }

  public onPricesAndServicesCallback(data: IPricesAndServicesFormData) {
    this.service.formGroup.controls.totalPriceFrom.setValue(data.totalPriceFrom ?? null);
    this.service.formGroup.controls.totalPriceTo.setValue(data.totalPriceTo ?? null);
    this.service.formGroup.controls.hotelServices.setValue(data.hotelServices ?? null);
  }
  public reset() {
    this.service.resetForm();
    this.modalRef?.close(true);
  }
  public submit() {
    this.service.onSubmit();
    if (this.service.formGroup.valid) this.modalRef?.close(true);
  }
  public scrollTop() {
    window.scrollTo(0, 0);
  }
}
