export const NGXS_HOTELS_PERSISTENT_STATE_KEYS: string[] = ['hotels.searchForm'];
export const NGXS_HOTELS_SEARCH_FORM = 'hotels.searchForm';

export const HOTEL_COLUMNS_STATIC_DATA = [
  { value: 'SortCheckInOut', label: 'Check-in date' },
  { value: 'SortHotelName', label: 'Hotel name' },
  { value: 'SortDetails', label: 'Details' },
  { value: 'SortPlacement', label: 'Rooms and capacity' },
  { value: 'TotalPrice', label: 'Price' }
];
