<form
  class="page-container"
  [formGroup]="service.formGroup"
  [ngxsForm]="service.ngxsForm"
  (ngSubmit)="service.onSubmit()"
>
  <div
    class="grid grid-cols-1 gap-x-10 flex flex-row w-full sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2"
  >
    <div class="mt-2 featured-box bg-kz-layout-featured pt-5 px-3">
      <div class="flex flex-row gap-2">
        <div class="w-full">
          <app-kz-desktop-select
            optionLabel="countryName"
            optionValue="countryCode"
            formControlName="countryCode"
            inputId="countryCode"
            optionIcon="icon"
            placeholder="Country"
            icon="map-marker"
            [iconByOption]="true"
            [options$]="service.countries$"
            [extraOptions]="service.countriesExtraOptions"
            [errorMessage]="service.formGroup.controls.countryCode.errors ?? null"
            [suppressOptionsTranslate]="true"
            (selectedOptionData)="service.onCountryChange($event)"
          />
        </div>
        <div class="w-full">
          <app-kz-desktop-button-popup
            icon="man"
            class="flex-1"
            [errorState]="service.isSubmited && service.hasChildrenAgesError"
          >
            <ng-template #templateLabel>
              <div>
                {{ service.formGroup.controls.adults.value ?? 0 }}
                {{
                  (service.formGroup.controls.adults.value && service.formGroup.controls.adults.value > 1
                    ? 'Adults'
                    : 'Adult'
                  ) | translate
                }}
              </div>
              <div>
                {{
                  service.formGroup.controls.children.value === 0
                    ? ('Without Children' | translate)
                    : service.formGroup.controls.children.value +
                      ' ' +
                      ((service.formGroup.controls.children.value && service.formGroup.controls.children.value > 1
                        ? 'Children'
                        : 'Child'
                      ) | translate)
                }}
              </div>
            </ng-template>
            <ng-template #templatePopupContent>
              <div class="mt-3">
                <app-kz-desktop-number-picker inputId="adults" formControlName="adults" [min]="1" [max]="10">{{
                  'Adults' | translate
                }}</app-kz-desktop-number-picker>
                <app-kz-desktop-number-picker
                  formControlName="children"
                  inputId="children"
                  [min]="0"
                  [max]="10"
                  (ngModelChange)="service.onAddChildren($event)"
                  >{{ 'Children' | translate }}</app-kz-desktop-number-picker
                >
                @if (service.formGroup.controls.children.value) {
                  <div class="mt-2">
                    <div class="divider"></div>
                    <div class="font-bold mb-2">{{ 'Children Ages' | translate }}:</div>
                    <div class="flex flex-wrap gap-2" formArrayName="childrenAges">
                      @for (c of service.childrenAges; track index; let index = $index) {
                        <app-kz-desktop-select
                          style="width: 64px; text-align: center"
                          placeholder="-"
                          [formControl]="c"
                          [options]="service.childrenAgesData"
                          [errorMessage]="service.isSubmited ? c.errors : null"
                          [inputId]="'children_age_' + index"
                        />
                      }
                    </div>
                  </div>
                }
              </div>
            </ng-template>
          </app-kz-desktop-button-popup>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col bg-kz-layout-featured p-3 pb-0 mt-2">
        <div class="grid grid-cols-5 gap-2 mt-2">
          <div class="col-span-2">
            <app-kz-desktop-date-picker
              formControlName="checkInDate"
              inputId="checkInDate"
              placeholder="Check In"
              [doubleCalendar]="true"
              [errorMessage]="service.formGroup.controls.checkInDate.errors"
              [minDate]="service.today"
              (ngModelChange)="service.calcCheckInAndCheckOutDaysCount(true)"
            />
          </div>
          <div class="flex flex-row gap-2 items-center days-input col-span-1">
            <input
              kzInput
              type="number"
              id="hotelDepartureAndReturnDaysCount"
              placeholder="-"
              max="30"
              formControlName="checkInAndCheckOutDaysCount"
              [errorMessage]="
                service.formGroup.controls.checkInAndCheckOutDaysCount.errors?.['minNumbers'] &&
                service.formGroup.controls.checkInAndCheckOutDaysCount.errors
              "
              [errorState]="service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount"
              (keyup)="service.updateCheckInAndCheckOutDaysCount()"
              (blur)="service.calcCheckInAndCheckOutDaysCount()"
            />
          </div>
          <div class="col-span-2">
            <app-kz-desktop-date-picker
              formControlName="checkOutDate"
              inputId="checkOutDate"
              placeholder="Check Out"
              [minDate]="service.formGroup.controls.checkInDate.value ?? service.today"
              [errorMessage]="
                !service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount
                  ? service.formGroup.controls.checkOutDate.errors
                  : null
              "
              [errorState]="service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount"
              [doubleCalendar]="true"
              (ngModelChange)="service.calcCheckInAndCheckOutDaysCount()"
            >
              <ng-template #templateContentFooter>
                @if (service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount) {
                  <div class="error-message text-center">
                    {{ 'The duration of stay should not exceed 30 nights from the Check In date' | translate }}
                  </div>
                }
              </ng-template></app-kz-desktop-date-picker
            >
          </div>
          <div class="col-span-5">
            <small class="error-message">
              {{
                service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount
                  ? ('The duration of stay should not exceed 30 nights from the Check In date' | translate)
                  : '&nbsp;'
              }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="grid grid-cols-1 gap-x-10 flex items-center flex-row w-full mt-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2"
  >
    <button type="button" class="link-btn" (click)="service.openInstructions()">
      {{ 'Instructions for booking hotels' | translate }}
    </button>
    <span>
      {{ 'Cost of inter-hotel and individual transfers' | translate }} -
      <a
        class="font-semibold lowercase"
        href="https://kazunion.com/индивидуальные-трансферы-оаэ/"
        target="_blank"
        >{{ 'Here' | translate }}</a
      >
    </span>
  </div>
  <div
    class="grid grid-cols-1 gap-x-10 flex flex-row w-full mt-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2"
  >
    <div>
      <app-kz-desktop-multi-select-box
        inputId="locations"
        title="City"
        optionLabel="label"
        optionValue="value"
        optionParentField="parent"
        optionSearchField="tag"
        formControlName="locations"
        [typedToken]="service.locationsTypedToken"
        [options$]="service.locations$"
        [showAnySelector]="true"
        [returnOptionsAsValue]="true"
        (ngModelChange)="service.updateHotelFilters()"
      />
      <mat-checkbox class="mt-2" formControlName="freeSale" color="primary">{{ 'Free sale' | translate }}</mat-checkbox>
    </div>
    <div>
      <app-kz-desktop-multi-select-box
        title="Hotels"
        optionLabel="hotelName"
        optionValue="hotelCode"
        formControlName="hotelCodes"
        inputId="hotelCodes"
        [typedToken]="service.availableHotelsTypedToken"
        [options$]="service.availableHotels$"
        [showAnySelector]="true"
        [optionFilterPredicate]="service.hotelsOptionFilterPredicate"
      >
        <ng-template #templateOptionLabel let-option>
          {{ option.hotelName | translate }} <app-kz-stars class="pb-1 pl-2" [stars]="option.hotelClass ?? 0" />
        </ng-template>
      </app-kz-desktop-multi-select-box>
      <!-- //disabled untill back fix
      <mat-checkbox class="mt-2" formControlName="groupByHotel" color="primary">{{
        'Group by hotels' | translate
      }}</mat-checkbox>
       -->
    </div>
  </div>
  <div
    class="grid grid-cols-1 gap-5 mt-4 w-full additional-params-box sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6"
  >
    <div>
      <label for="totalPriceFrom" class="kz-label">{{ 'Price' | translate }}</label>
      <div class="flex flex-row mt-2 gap-3 justify-between">
        <div>
          <input
            id="totalPriceFrom"
            formControlName="totalPriceFrom"
            type="number"
            kzInput
            class="text-center"
            placeholder="From"
            min="0"
            [errorMessage]="service.formGroup.controls.totalPriceFrom.errors"
          />
        </div>
        <div>
          <input
            id="totalPriceTo"
            formControlName="totalPriceTo"
            type="number"
            kzInput
            class="text-center"
            placeholder="To"
            min="0"
            [errorMessage]="service.formGroup.controls.totalPriceTo.errors"
          />
        </div>
      </div>
    </div>
    @if (currencies$ | async; as currencies) {
      <div class="pt-[29px] currency-select">
        <app-kz-desktop-select
          optionLabel="symbol"
          optionValue="code"
          [options]="currencies"
          [value]="activedCurrency$ | async"
          (valueChange)="onCurrencySelect($event)"
        >
          <ng-template #templateSelected let-option> {{ option.code }} - {{ option.symbol }} </ng-template>
          <ng-template #templateOptionLabel let-option> {{ option.code }} - {{ option.symbol }} </ng-template>
        </app-kz-desktop-select>
      </div>
    }
    <div>
      <app-kz-desktop-multi-select
        formControlName="mealPlans"
        inputId="mealPlans"
        placeholder="Any"
        label="Meal plan"
        optionLabel="value"
        [options]="service.mealPlans"
        [errorMessage]="service.formGroup.controls.mealPlans.errors"
      />
    </div>
    <div>
      <app-kz-desktop-multi-select
        formControlName="hotelClasses"
        inputId="hotelClasses"
        optionLabel="label"
        placeholder="Any"
        label="Number of stars"
        [options]="service.hotelClasses"
        [errorMessage]="service.formGroup.controls.hotelClasses.errors"
        (ngModelChange)="service.updateHotelFilters()"
      >
        <ng-template #templateOptionLabel let-option>
          @if (option.value) {
            <app-kz-stars [stars]="option.value | parseInt" [hideEmptyStars]="true" />
          }
        </ng-template>
      </app-kz-desktop-multi-select>
    </div>
    <div>
      <app-kz-desktop-multi-select
        placeholder="Any"
        label="Categories"
        inputId="hotelTypes"
        formControlName="hotelTypes"
        [options]="service.hotelTypes"
        [errorMessage]="service.formGroup.controls.hotelTypes.errors"
        (ngModelChange)="service.updateHotelFilters()"
      />
    </div>
    <div>
      <app-kz-desktop-multi-select
        placeholder="Any"
        label="Services"
        formControlName="hotelServices"
        inputId="hotelServices"
        [options]="service.hotelServices"
        [errorMessage]="service.formGroup.controls.hotelServices.errors"
      />
    </div>
  </div>
  <p class="text-kz-danger mt-3">
    {{
      service.isSubmited && service.formGroup.invalid ? ('Please fill all required fields correctly' | translate) : ''
    }}
  </p>
  @if (service.formStatus$ | async; as formStatus) {
    <button
      mat-flat-button
      color="primary"
      class="medium-size mt-2"
      type="submit"
      [disabled]="service.isSubmited && service.formGroup.invalid"
      [loading]="formStatus.status === 'loading'"
    >
      {{ 'Search' | translate }}
    </button>
  }
</form>
