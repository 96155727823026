import { ChangeDetectorRef, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import {
  CalcOnlyHotelPackageRequest,
  CalcOnlyHotelPackageResponse,
  HotelExtraServiceBase,
  HotelExtraServicePriceBase,
  RefreshPriceResultEnum
} from '@appTypes/api.types';
import { IOnlyHotelPackage } from '@appTypes/redefined-types';
import { ApiErrorResponse } from '@core/interceptors/api.interceptor';
import { StoreService } from '@core/store/store.service';
import { SVG_ICONS } from '@data/svg-icons.data';
import { HotelsApiService } from '@pages/hotels-page/services/hotels-api.service';
import { HotelsSearchFormService } from '@pages/hotels-page/services/hotels-search-form.service';
import { HotelsState } from '@pages/hotels-page/state/hotels.state';
import { cartAddItem } from '@shared/modules/cart/state/cart.actions';
import { ConfirmService } from '@shared/modules/confirm/confirm.service';
import { ConfirmParams, ConfirmType } from '@shared/modules/confirm/confirm.types';
import { DYNAMIC_MODAL_DATA_TOKEN } from '@shared/modules/dynamic-modal/dynamic-modal.const';
import { DynamicModalRef } from '@shared/modules/dynamic-modal/dynamic-modal.types';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-hotel-details-desktop-view',
  templateUrl: './hotel-details-desktop-view.component.html',
  styleUrl: './hotel-details-desktop-view.component.scss',
  providers: []
})
export class HotelDetailsDesktopViewComponent {
  @ViewChild('modalFooterTemplateRef') public modalFooterTemplateRef!: TemplateRef<unknown>;

  private modalRef!: DynamicModalRef<HotelDetailsDesktopViewComponent>;

  private readonly svgIcons = SVG_ICONS;

  public extraServicePriceTypedToken!: HotelExtraServicePriceBase;
  public extraServiceSelectorTemplateTypedToken!: { option: HotelExtraServicePriceBase };

  public package: IOnlyHotelPackage | null = null;
  public includedInPackagePrice: boolean | undefined = this.data?.insurancePrice?.includedInPackagePrice;
  public readonly _insuranceIncludedFormatBugIssue = 'Insurance ({{price}}) with COVID coverage';

  public get hotelData() {
    return { ...this.package?.hotel };
  }
  public getHotelExtraParams = (id: string) => {
    return this.storeService.selectSnapshot(HotelsState.getHotelExtraParams(id));
  };

  public getSelectedExtraServiceDataFn = ({
    extraServiceCode,
    includedPriceCode
  }: Pick<HotelExtraServiceBase, 'extraServiceCode' | 'includedPriceCode'>) =>
    this.package?.extraServices
      ?.find((e) => e.extraServiceCode === extraServiceCode)
      ?.prices?.find((e) => e.extraServicePriceCode === includedPriceCode);

  public isLoading = true;
  public allowAddToCart = true;
  constructor(
    @Inject(DYNAMIC_MODAL_DATA_TOKEN) public data: IOnlyHotelPackage,
    private storeService: StoreService,
    private hotelsApiService: HotelsApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmService: ConfirmService,
    private searchService: HotelsSearchFormService
  ) {
    this.calcPackage(
      {
        packageId: this.data?.id,
        includeInsurance: this.data.insurancePrice?.includedInPackagePrice
      },
      true
    );
  }

  public onExtraServiceSelect(extraServiceCode: string, data: HotelExtraServicePriceBase | null) {
    if (!this.package?.extraServices) return;

    const extraServicePriceCodes: string[] = data?.extraServicePriceCode ? [data.extraServicePriceCode] : [];

    //take `PriceCodes` codes from other `extraServices`
    for (const iterator of this.package.extraServices)
      if (extraServiceCode !== iterator.extraServiceCode && iterator.includedPriceCode)
        extraServicePriceCodes.push(iterator.includedPriceCode);

    this.isLoading = true;
    this.calcPackage({ packageId: this.package?.id, extraServicePriceCodes });
  }

  public onAddCart() {
    if (!this.package?.totalPrice || !this.package.priceCurrency) return;
    this.storeService.dispatch(
      new cartAddItem({
        type: 'hotel',
        data: this.package,
        price: this.package.totalPrice,
        priceCurrency: this.package.priceCurrency
      })
    );
    this.modalRef.close();
  }

  calcPackage(payload: CalcOnlyHotelPackageRequest, checkPrice = false) {
    this.hotelsApiService
      .calcPackage(payload)
      .pipe(
        catchError((e: ApiErrorResponse) => {
          this.isLoading = false;
          this.allowAddToCart = false;
          this.package = null;
          this.showErrorModal(
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you",
            { type: 'error', activityId: e.activityId }
          );
          return throwError(() => e);
        })
      )
      .subscribe((e) => {
        if (e.package) {
          this.package = e.package;
          this.isLoading = false;
          if (!this.allowAddToCart) this.allowAddToCart = true;
        }
        if (e.error) {
          this.showErrorModal(e.errorDescription || '', 'error');
          return;
        }
        this.handleRefreshPriceResult(e, checkPrice);
      });
  }

  private showErrorModal(message: string, params: ConfirmParams | ConfirmType, refreshResults = false) {
    this.confirmService.confirm(message, params).subscribe(() => {
      if (refreshResults) {
        this.modalRef.close();
        this.searchService.onSubmit();
      }
    });
  }

  private handleRefreshPriceResult(response: CalcOnlyHotelPackageResponse, checkPrice = false) {
    switch (response?.refreshPriceResult) {
      case RefreshPriceResultEnum.HotelPriceNoMoreAvailable:
        this.showErrorModal('Hotel price no more available', 'error', true);
        break;
      case RefreshPriceResultEnum.FlightPriceNoMoreAvailable:
        this.showErrorModal('Flight price no more available', 'error', true);
        break;
      default:
        this.updatePackage(response);
        break;
    }

    if (response?.refreshPriceResult === RefreshPriceResultEnum.Success && checkPrice) {
      const totalPrice = response?.package?.totalPrice;
      const existingPrice = this.data.totalPrice;

      if (totalPrice && existingPrice && existingPrice > totalPrice) {
        this.confirmService.confirm('Price has been reduced', 'info');
      } else if (totalPrice && existingPrice && existingPrice < totalPrice) {
        this.confirmService.confirm('Price has been increased', 'info');
      }
    }
  }

  updatePackage(data: CalcOnlyHotelPackageResponse) {
    const _extraParams = this.getHotelExtraParams(data?.package?.id as string);
    const packageWithExtraParams = { ...data.package, hotel: { ...data?.package?.hotel, _extraParams: _extraParams } };
    this.package = packageWithExtraParams || null;
    this.includedInPackagePrice = data.package?.insurancePrice?.includedInPackagePrice;
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }

  public onchange(e: boolean) {
    if (!this.package) return;
    this.isLoading = true;
    this.calcPackage({
      packageId: this.package.id,
      includeInsurance: e
    });
  }
}
