import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OnlyHotelPackage } from '@appTypes/api.types';
import { IS_MOBILE } from '@core/misc/misc.utils';
import { StoreService } from '@core/store/store.service';
import { KzDesktopLoadingWindowService } from '@shared/components/desktop/kz-desktop-loading-window/kz-desktop-loading-window.service';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { CatalogueApiService } from '@shared/services/api/catalogue-api.service';
import { HotelDetailsDesktopViewComponent } from '../desktop/hotel-details-desktop-view/hotel-details-desktop-view.component';
import { HotelDetailsMobileComponent } from '../mobile/hotel-details-mobile/hotel-details-mobile.component';
import { HotelsApiService } from './hotels-api.service';

@Injectable()
export class HotelsCommonService {
  constructor(
    private matDialog: MatDialog,
    private dynamicModalService: DynamicModalService,
    public loadingWindow: KzDesktopLoadingWindowService,
    public catalogueApi: CatalogueApiService,
    public api: HotelsApiService,
    public storeService: StoreService
  ) {}

  public openDetails(data: OnlyHotelPackage) {
    if (IS_MOBILE)
      return this.dynamicModalService.open({
        title: 'Particulars',
        component: HotelDetailsMobileComponent,
        height: '85vh',
        data
      });
    else
      return this.dynamicModalService.open({
        title: 'Particulars',
        component: HotelDetailsDesktopViewComponent,
        data
      });
  }
}
