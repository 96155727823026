import {
  GetCountriesResponse,
  Hotel,
  SearchOnlyHotelPackagesRequest,
  SearchOnlyHotelPackagesResponse
} from '@appTypes/api.types';
import { DistrictsTreeItem } from '@appTypes/redefined-types';
import { RequestStateModel, StateFormModel } from '@core/store/store.models';

export class HotelsSearchFormDataModel {
  locations = new RequestStateModel<DistrictsTreeItem[]>();
  hotels = new RequestStateModel<Hotel[]>();
  destinationCountries = new RequestStateModel<GetCountriesResponse>();
}
export class HotelsStateModel {
  searchForm = new StateFormModel<SearchOnlyHotelPackagesRequest>();
  searchFormData = new HotelsSearchFormDataModel();
  /*  */
  searchResults = new RequestStateModel<SearchOnlyHotelPackagesResponse>();
}
