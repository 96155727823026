<div class="relative">
  <div class="p-4">
    <div>
      <p class="mb-5">
        {{ 'Instructions for booking hotels' | translate }} (<a
          class="font-semibold"
          href="https://kazunion.com/бронирование-отелей-в-новом-онлайне/"
          target="_blank"
          >ссылка</a
        >)
      </p>

      <div class="my-3">
        <p class="font-semibold">В стоимость тура включено:</p>
        <ul class="m-0">
          <li>проживание в выбранном отеле с указанной концепцией питания</li>
          <li>комиссия агентства</li>
          <li>страховка (добровольная)</li>
        </ul>
      </div>
    </div>
  </div>
</div>
