import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OnlyHotelPackage, SearchOnlyHotelPackagesResponse } from '@appTypes/api.types';
import { IOnlyHotelPackage } from '@appTypes/redefined-types';
import { RequestStateModel } from '@core/store/store.models';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { HOTEL_COLUMNS_STATIC_DATA } from '@pages/hotels-page/hotels-page.const';
import { HotelsColumnsSort, SearchOnlyHotelPackagesPaging } from '@pages/hotels-page/hotels.types';
import { HotelsSearchFormService } from '@pages/hotels-page/services/hotels-search-form.service';
import { hotelsOpenDetails, hotelsSearchHotels } from '@pages/hotels-page/state/hotels.actions';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-hotels-result-mobile',
  templateUrl: './hotels-result-mobile.component.html',
  styleUrl: './hotels-result-mobile.component.scss'
})
export class HotelsResultMobileComponent {
  @Input() public searchResults!: RequestStateModel<SearchOnlyHotelPackagesResponse>;
  @ViewChild('childItemsViewTemplateRef') childItemsViewTemplateRef!: TemplateRef<unknown>;

  public columnSortOrders?: HotelsColumnsSort;
  public sortableColumns = HOTEL_COLUMNS_STATIC_DATA;

  public readonly itemsTypedTemplateToken!: { item: OnlyHotelPackage; isChild?: boolean; index: number };
  public selectedChildren: OnlyHotelPackage[] = [];

  constructor(
    private storeService: StoreService,
    public service: HotelsSearchFormService,
    private translateService: TranslateService,
    private dynamicModalService: DynamicModalService,
    private snackBar: MatSnackBar
  ) {}

  public onPageSelect(paging: SearchOnlyHotelPackagesPaging) {
    window.scrollTo(0, 0);
    this.storeService.dispatch(new hotelsSearchHotels({ ...paging, ...this.columnSortOrders }));
  }

  public onOpenChildren(selectedChildren: OnlyHotelPackage[]) {
    this.selectedChildren = selectedChildren;
    const modalRef = this.dynamicModalService.open({
      title: 'All prices of this hotel',
      template: this.childItemsViewTemplateRef,
      height: '85vh'
    }) as DialogRef;
    modalRef.closed.pipe(take(1)).subscribe(() => (this.selectedChildren = []));
  }

  public onOpenDetails(data: IOnlyHotelPackage) {
    this.storeService.dispatch(new hotelsOpenDetails(data));
  }
  public onPerPageChange(paging: SearchOnlyHotelPackagesPaging) {
    this.service.pageRowCount.set(paging.pageRowCount ?? 25);
    this.storeService.dispatch(new hotelsSearchHotels({ ...paging, ...this.columnSortOrders }));
  }

  public sortColumn(sort: SearchOnlyHotelPackagesPaging) {
    if (this.searchResults.loadState.status === 'loading') {
      this.snackBar.open(
        this.translateService.instant('Data is already in the loading process. Please wait until it is fully loaded.'),
        '',
        {
          panelClass: 'snackbar-warning',
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        }
      );
      return;
    }

    this.columnSortOrders = {
      sortByField: sort.sortByField as HotelsColumnsSort['sortByField'],
      sortOrder: sort.sortOrder as HotelsColumnsSort['sortOrder']
    };
    this.storeService.dispatch(new hotelsSearchHotels(sort));
  }
}
